import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layout'),
    children:[
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home')
      },
      {
        path: '/en',
        name: 'enHome',
        component: () => import('@/views/en_home')
      },
      {
        path: '/meirun',
        name: 'meirun',
        component: () => import('@/views/customer/meirunCustom.vue')
      },
      {
        path: '/exhibition',
        name: 'exhibition',
        component: () => import('@/views/exhibition/index.vue')
      },
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
